<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ข้อมูลผู้ใช้งาน / Profile
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light"
          style="font-size: 14px; font-weight: bold"
          @click="goBack()"
        >
          กลับ
        </button>
      </div>
    </div>

    <div class="card-title row ms-6 me-5 mt-10">
      <!-- <div class="symbol symbol-100px symbol-circle mb-10"> -->
      <div class="d-flex justify-content-start mb-10">
        <img
          class="shadow-sm"
          alt="Logo"
          :src="
            user.user_picture
              ? user.user_picture
              : imgShow
              ? imgShow
              : ' media/avatars/blank.png'
          "
          style="
            border-radius: 49%;
            border: 1.5px solid Gainsboro;
            width: 140px;
          "
        />
        <i
          @click="onClickUpload()"
          class="bi bi-camera"
          style="font-size: 23px; cursor: pointer"
        >
          <input
            @change="onFileChange($event)"
            type="file"
            id="input-img"
            style="display: none"
            accept="image/png, image/jpeg"
          />
        </i>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">Username</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.username }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">ชื่อผู้ใช้งาน</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.firstName }} {{ this.user.lastName }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">Email</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.email }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">เบอร์โทร</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.telNumber }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">กิจการ</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>
          {{ this.user.company ? this.user.company.company_name : "-" }}
        </div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">บทบาท</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div :class="classRole">
          {{ this.user.role }}
        </div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">สถานะ</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>
          <div
            class="form-check form-switch form-check-custom form-check-solid"
          >
            <input
              v-model="statusActive"
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexSwitchDefault"
              style="height: 1.8rem"
              @change="activeChange()"
              :disabled="isChangeStatus"
            />
            <label class="form-check-label" for="flexSwitchDefault"
              >เปิดใช้งาน (Active)</label
            >
          </div>
        </div>
      </div>
    </div>

    <AddItem
      :dialogAddItem="dialogAddItem"
      :dataEditItem="dataEditItem"
      @submitAddItem="submitAddItem"
      @closeDialogAddItem="closeDialogAddItem"
    />
  </div>
</template>

<script>
import userApi from "@/api/user/";
import apiConfig from "@/config/api";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Decode, Encode } from "@/services";

import AddItem from "../../components/upload/dialogCropImg.vue";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "BELUCA | ข้อมูลผู้ใช้งาน";
  },
  data: () => ({
    userId: "",
    user: [],

    statusActive: false,

    classRole: "badge badge-lg badge-light-success fw-bolder fs-6",

    isChangeStatus: false,

    dialogAddItem: false,
    dataEditItem: "",

    userId: "",
    imgName: "",
    imgShow: "",

    role: "",
    roleUser: "",
  }),
  async created() {
    await this.getUser();

    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );

    this.checkRole();
  },
  methods: {
    async getUser() {
      const id = this.$route.query.id;
      let responseData = [];
      try {
        responseData = await userApi.user.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
        this.userId = responseData.data.id;
        this.imgShow = responseData.data.file
          ? `${apiConfig.ENDPOINT}${responseData.data.file.path}`
          : "media/avatars/blank.png";

        this.statusActive = responseData.data.statusActive;
        this.setClassRole();
      }
    },

    checkRole() {
      if (this.role === this.user.role) {
        this.isChangeStatus = true;
      } else {
        if (
          this.role === "ROOT" ||
          this.role === "SYSTEM ADMIN" ||
          this.role === "BUSINESS OWNER" ||
          this.role === "BUSINESS DIRECTOR"
        ) {
          this.isChangeStatus = false;
        } else {
          this.isChangeStatus = true;
        }
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    setClassRole() {
      this.user["role"] === "SYSTEM ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-primary fw-bolder fs-6")
        : this.user["role"] === "ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-success fw-bolder fs-6")
        : this.user["role"] === "MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-warning fw-bolder fs-6")
        : this.user["role"] === "BRANCH MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-danger fw-bolder fs-6")
        : this.user["role"] === "STAFF"
        ? (this.classRole = "badge badge-lg badge-light-info fw-bolder fs-6")
        : (this.classRole =
            "badge badge-lg badge-light-success fw-bolder fs-6");
    },

    async activeChange() {
      const response = await userApi.user.update(this.user.id, {
        statusActive: this.statusActive,
      });

      if (response.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "อัพเดทสถานะสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "อัพเดทสถานะไม่สำเร็จ",
          showConfirmButton: false,
          timer: 1700,
        });
      }
    },

    // product
    addItem(pic) {
      this.dialogAddItem = true;
      this.dataEditItem = pic;
    },

    closeDialogAddItem() {
      this.dialogAddItem = false;
    },

    async submitAddItem(item) {
      this.closeDialogAddItem();
      this.imgShow = item.canvas.toDataURL(this.image.type);
      let file = await this.dataURLtoFile(this.imgShow, this.imgName);
      if (file) {
        this.uploadPic(file);
      }
    },

    // upload pic
    onClickUpload() {
      document.getElementById("input-img").click();
    },

    async onFileChange(e) {
      const selectImg = e.target.files[0];
      this.imgName = selectImg.name;
      const response = await this.createBase64Img(selectImg);

      if (response) {
        this.image = response;
        this.addItem(response);
      }
    },

    async createBase64Img(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },

    async dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    async uploadPic(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "upload-file");
      formData.append("name", file.name);
      formData.append("size", file.size);
      formData.append("userId", this.userId);

      const response = await userApi.upload.upload(formData);
      const responseUser = await userApi.user.update(this.userId, {
        user_picture: null,
      });

      if (
        response.response_status === "SUCCESS" &&
        responseUser.response_status === "SUCCESS"
      ) {
        Swal.fire({
          icon: "success",
          title: `อัพโหลดรูปภาพสำเร็จ`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.$router.go(0);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `อัพโหลดรูปภาพไม่สำเร็จ`,
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
